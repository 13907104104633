<template>
  <div>
    <b-modal ref="loading-data" size="md" hide-header hide-footer centered content-class="darkmodal">
      <b-row>
        <b-col class="text-center">
          <b-spinner class="align-middle"></b-spinner>
          <h5 style="margin-top:20px">LOADING COMPANY DATA...</h5>
        </b-col>
      </b-row>
    </b-modal>
    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import ErrorLogging from './ErrorLogging.vue';

export default {
  props: ['reloaddata', 'dialog'],
  name: 'LoadCompanyData',
  components: {
    ErrorLogging,
  },
  data() {
    return {
      errorresponse: {},
    };
  },


  created() {
   
  },

  computed: mapState(['companyid']),

  watch: {
    companyid() {
      this.$store.commit('setrovers', [])
      this.$store.commit('setrovers', [])
      this.$store.commit('setroversevents', [])
      this.$store.commit('setroversmap', [])
      this.loadAllCompanyData()
    },
    reloaddata() {
      if(this.reloaddata == 0){return}  
     
      //if(this.reloaddata == 1){
          this.loadData(this.reloaddata)
     // } else
      //this.fetchRovers2()
    },
  },

  methods: {

    async loadData(type) {    //   1 - Company 2 - Rovers 3 - Rovers/Subs/Sims - 4 just subs - 5 fetch session 

      if(this.dialog != false){this.$refs['loading-data'].show()} 

      switch (type) {
        case 1:
          await this.fetchCompany()
          break
        case 2:
          await this.fetchRovers()
          break
        case 3:
          await this.fetchRovers()
          await this.fetchSubs()
          break
        case 4:
          await this.fetchSubs()
          break
        case 5:
          await this.fetchRoverSession()
          break;
        case 6:
          await this.fetchRoversMap()
          break    
           
      }
      if(this.dialog != false){this.$refs['loading-data'].hide()}

    },

    async loadAllCompanyData() {
      if(this.dialog != false){ this.$refs['loading-data'].show() }
      await this.fetchCompany()
      await this.fetchRovers()
      await this.fetchSubs()
      if(this.dialog != false){ this.$refs['loading-data'].hide() }
      this.$router.push({ name: 'homepage', params: {} }).catch(() => { }) // Else just show home

    },

    async fetchCompany() {
      return new Promise((resolve,reject) => {
        axios.get('/api/portalcompany', {
          headers: { "Authorization": "Bearer " + this.$store.getters.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.$store.getters.token,
            id: this.companyid
          }
        })
          .then((response) => {
            if (response.data.code == 999) {
              //this.errorresponse = response.data
              console.log(response.data)
              reject();
            } else {
              console.log(response)  
              this.$store.commit('setcompany', response.data.company[0])
              resolve(true)
            }
          })        
          .catch((err) => {
            this.errorresponse = err;
            reject();
          });

      })

    },

    async fetchRovers() {
      return new Promise((resolve, reject) => {
        // Get Company Rovers
        axios.get('/api/portalcompanyroverslive', {
          headers: { "Authorization": "Bearer " + this.$store.getters.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.$store.getters.token,
            company: this.companyid,
            order:1
          }
        }).then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data;
            reject();
          } else {
            this.$store.commit('setrovers', response.data.rovers)
            this.$store.commit('setroversmap', response.data.roversmap)
            let events = []
            response.data.rovers.forEach(element => {
              if (element.events.length > 0) {
                                if(element.events[0]['session_logs'] != ''){
                        events.push(...JSON.parse(element.events[0]['session_logs']))
                                }     
              }
            });

            // Sort events by timestamp
            events.sort((a, b) => {
              if (a.Timestamp < b.Timestamp) { return -1; }
              if (a.Timestamp > b.Timestamp) { return 1; }
              return 0;
            });

            this.$store.commit('setroversevents', events)
            resolve(true)
          }
        })
        .catch((err) => {
          this.errorresponse = err;
          reject();
        });

      })
    },

    async fetchRoversMap() {
      return new Promise((resolve, reject) => {
        // Get Company Rovers Map data
        axios.get('/api/portalcompanyroverslive', {
          headers: { "Authorization": "Bearer " + this.$store.getters.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.$store.getters.token,
            company: this.companyid,
            order:1,
            map:true
          }
        }).then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data;
            reject();
          } else {
            this.$store.commit('setroversmap', response.data.roversmap)
            resolve(true)
          }
        })
        .catch((err) => {
          this.errorresponse = err;
          reject();
        });

      })
    },

    async fetchStock() {
      return new Promise((resolve, reject) => {
        // Get Stock simms
        axios.get('/api/castersimmscompany', {
          headers: { "Authorization": "Bearer " + this.$store.getters.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.$store.getters.token,
            stock: 1,
            company: this.companyid,
          }
        })
          .then((response) => {
            if (response.data.code == 999) {
              this.errorresponse = response.data;
              reject();
            } else this.$store.commit('setstocksims', response.data.CasterSimms)
          })
          .catch((err) => {
            this.errorresponse = err;
            reject();
          });

        // Get Stock subs 

        axios.get('/api/subscriptionsstockportal', {
          headers: { "Authorization": "Bearer " + this.$store.getters.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.$store.getters.token,
            company: this.companyid
          }
        }).then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data;
            reject();
          } else {
            let subs = response.data.CasterSubs
            if (subs.length > 0) { this.$store.commit('setstocksubs', subs) } else this.$store.commit('setstocksubs', [])
            resolve(true)
          }

        })
        .catch((err) => {
          this.errorresponse = err;
          reject();
        });
      })
    },

    async fetchSubs() {
      // Get current subscriptions 
      return new Promise((resolve, reject) => {
        axios.get('/api/casterntripsubscriptions', {
          headers: { "Authorization": "Bearer " + this.$store.getters.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.$store.getters.token,
            //status:this.status,
            company: this.companyid,
            portal: true
          }
        }).then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data;
            reject();
          } else {
            let subs = response.data.CasterSubs
            if (subs.length > 0) { this.$store.commit('setsubs', subs) } else this.$store.commit('setsubs', [])
          }
        })
        .catch((err) => {
            this.errorresponse = err;
            reject();
        });

        // Get Stock simms
        axios.get('/api/castersimmscompany', {
          headers: { "Authorization": "Bearer " + this.$store.getters.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.$store.getters.token,
            stock: 1,
            company: this.companyid,
          }
        })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data;
            reject();
          } else this.$store.commit('setstocksims', response.data.CasterSimms)
        })
        .catch((err) => {
          this.errorresponse = err;
          reject();
        });


        axios.get('/api/subscriptionsstockportal', {
          headers: { "Authorization": "Bearer " + this.$store.getters.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.$store.getters.token,
            company: this.companyid
          }
        }).then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data;
            reject();
          } else {
            let subs = response.data.CasterSubs
            if (subs.length > 0) { this.$store.commit('setstocksubs', subs) } else this.$store.commit('setstocksubs', [])
            resolve(true)
          }

        })
        .catch((err) => {
            this.errorresponse = err;
            reject();
        });

      })

    },

    async fetchRoverSession(){
    // Get current Rover session data 
    return new Promise((resolve, reject) => {

    if(this.$store.getters.roverid == 0){resolve(true)}  

    axios.get('/api/portalroversession',{
                headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                params:{
                    userid:this.$store.getters.user.id,
                    token:this.$store.getters.user.token, 
                    rover: this.$store.getters.roverid,
                    session:0
                }
        })
        .then((response) => {
            
            if(response.data.code == 999){
                console.log(response.data)
               /// this.errorresponse = response.data
                reject();
                } else {
                  this.$store.commit('setroversession',response.data)
                  resolve(true)
                }
            })
            .catch((err) => {
              this.errorresponse = err;
              reject();
             });     
            })

      },                

  }
}         
</script>
