<template>
  <div>
    <b-row>
      <b-col class="col-sm-6">
        <b-icon-geo class="homepageicon" />
        <h4>Rovers</h4>
      </b-col>

      <b-col class="col-6 text-right">
        <b-button variant="outline-success" size="sm" @click.stop="menuLink('roversdisplay')"
          style="margin-right:5px"><b-icon-map /></b-button>
        <b-button variant="outline-success" size="sm" @click.stop="addRover()"><b-icon-plus-circle /></b-button>
      </b-col>
    </b-row>

    <b-row  v-if="rovers.length == 0" lass="mt-5 border m-5 pt-3 pb-5">
      <b-col class="col-12 mt-3 text-center">
        <h5>No Rovers Found</h5>
      </b-col>
    </b-row>

        <b-row class="mt-3 border pt-1 pb-1" v-if="rovers.length != 0"> 
          <b-col class="col-12" style="color:rgba(255,255,255,0.7)">
            <div class="rtkstats">FIXED {{ fix }}</div>
            <div class="rtkstats">FLOAT {{ float }}</div>
            <div class="rtkstats">DGPS {{ dgps }}</div>
            <div class="rtkstats">GPS {{ gps }}</div>
            <div class="rtkstats">EST {{ est }}</div>
          </b-col>    
        </b-row>
    
    <b-row>
      
      
      <b-col class="col-12 col-sm-6 col-xl-4 mt-3" v-for="roveslive in roverslive" :key="roveslive.id"
        @click.stop="viewRover(roveslive.value)">

        <div class="box" style="padding:3%">

          <b-row>

            <b-col class="col-10 mt-1">
              <h5>{{ roveslive.text }}</h5>
            </b-col>

            <b-col class="col-6 col-sm-6 mt-1">
              Machinery
            </b-col>

            <b-col class="col-6 col-sm-6 mt-1 text-sm-right">
              {{ roveslive.machine[0].make_title }} {{ roveslive.machine[0].model_title }}<br />
              {{ roveslive.machine[0].regnum }}
            </b-col>
          </b-row>


          <b-row v-if="roveslive.subscriptiondetail.length > 0" class="mt-3">
            <b-col class="col-6">RTK Status</b-col>
            <b-col class="col-6" v-if="roveslive.rtk_status != 0">
              <b-button :style="{ backgroundColor: setRTKColor(roveslive.rtk_status) }" block size="sm">{{ roveslive.statustext
                }}</b-button>
            </b-col>
            <b-col class="col-6" v-else>
              <b-button :style="{ backgroundColor: 'rgba(0,0,0,0.7)' }" block size="sm">not connected</b-button>
            </b-col>
          </b-row>

          <b-row class="mt-3" v-if="roveslive.subscriptiondetail.length > 0">
            <b-col class="col-6">
              Subscription
            </b-col>
            <b-col class="col-6" v-if="roveslive.subscriptiondetail[0].status != 'Scheduled'">
              <b-button :style="{ backgroundColor: setExpiryColor(roveslive.subscriptiondetail[0].expirydays) }" block
                size="sm">{{ roveslive.subscriptiondetail[0].expirydays }} days</b-button>
            </b-col>

            <b-col class="col-6" v-else>
              <b-button :style="{ backgroundColor: 'rgba(60, 210, 165, 0.7)' }" block
                size="sm">{{ roveslive.subscriptiondetail[0].status }} <br/> {{ formatDate(roveslive.subscriptiondetail[0].startdate) }}</b-button>
            </b-col>
          </b-row>

          <b-row class="mt-3" v-else>
            <b-col class="col-12">
              <b-button variant="outline-warning" size="md" block
                @click.stop="assignSub(roveslive)">{{ assignSubText }}</b-button>
            </b-col>
          </b-row>

        </div>


      </b-col>
    </b-row>
    
    <LoadCompanyData :reloaddata="reloaddata" :dialog="false" />

    </div> 
    </template>

    <style>
    .rtkstats{
        position: relative;
        float: left;
        width:20%;
        text-align: center;
    }
    </style>
    
    <script>
    import LoadCompanyData from './LoadCompanyData'
    import { mapState } from 'vuex'
    import moment from 'moment'
    export default {
    name: 'RoversListCard',
    components:{
        LoadCompanyData
    },
    data(){
    return {
            roverslive:[],
            fix:0,
            float:0,
            gps:0,
            dgps:0,
            est:0,
            connected:0,
            assignSubText: 'Assign subscription',
            reloaddata:0,
            timeout:false
            };
        },

    beforeDestroy () {
            clearInterval(this.timeout)
    },    

    created(){
        this.roverslive = this.$store.getters.rovers
        this.setStats()
        this.timeout = setInterval(this.getRoverdata,5000)
    },

    computed: mapState(['rovers']),

    watch: {
        rovers: function() {
            this.roverslive = this.rovers
            this.setStats()
        }
    },

  methods: {

        getRoverdata(){
            this.reloaddata = 2
            setTimeout(() => {this.reloaddata = 0}, 2000);
        },

        setStats(){
            this.gps = this.roverslive.filter(roversel => roversel.rtk_status == 1).length
            this.dgps = this.roverslive.filter(roversel => roversel.rtk_status == 2).length
            this.fix = this.roverslive.filter(roversel => roversel.rtk_status == 4).length
            this.float = this.roverslive.filter(roversel => roversel.rtk_status == 5).length
            this.est = this.roverslive.filter(roversel => roversel.rtk_status == 6).length
            this.connected = this.roverslive.filter(roversel => roversel.connected == 1).length
        },

    setRTKColor(status) {
      let color = 'rgba(255, 255, 255, 0.7)'
      switch (status) {
        case 1:
          color = 'rgba(255, 96, 96, 0.7)'
          break;
        case 2:
          color = 'rgba(255, 159, 12, 0.7)'
          break;
        case 4:
          color = 'rgba(60, 210, 165, 0.7)'
          break;
        case 5:
          color = 'rgba(48, 190, 255, 0.7)'
          break;
        case 6:
          color = 'rgba(102, 102, 102, 0.7)'
          break;
      }
      return color
    },

    setExpiryColor(days) {
      if (days > 1) { return 'rgba(60, 210, 165, 0.7)' } else return 'rgba(255, 96, 96, 0.7)'
    },

    assignSub(roveslive) {
      if (this.$store.getters.stocksubs.length == 0) {
        this.assignSubText = 'Purchase Subscription';
        setTimeout(() => {
          this.menuLink('subscriptionadd');
        }, 2000)
        return;
      }

      this.$router.push({ name: 'assignsubscription', params: { id: roveslive.value } }).catch(() => { })

    },

    editMachine(id) {
      this.$router.push({ name: 'machineedit', params: { id: id } }).catch(() => { })
    },

    addRover() {
      this.$router.push({ name: 'machinenew', params: { id: 0 } }).catch(() => { })
    },

    viewRover(id) {
      this.$store.commit('setroversession',null)
      this.$router.push({ name: 'roverdisplay', params: { id: id } }).catch(() => { })
    },

    viewSub(id) {
      this.$router.push({ name: 'subscription', params: { id: id } }).catch(() => { })
    },

    formatDate(timedate) {
      if (timedate == 0) { return '-' }
      let date = moment(timedate * 1000).format('DD MMM y HH:mm')
      return date
    },

    menuLink(name) {
      this.$router.push({ name: name, params: {} }).catch(() => { })
    },
  }
};
</script>