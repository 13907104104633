<template>
<div>

    <b-row class="mt-3 mb-5">
         <b-col class="col-12">
                <RoversListCard />
         </b-col>
         
    </b-row>   
   
</div> 
</template>
    
  
<script>
        import RoversListCard from './RoversListCard'
        export default {
            name: 'HomePage',
            components:{
                RoversListCard
                },
            data(){
            return {
                 
                   };
              },
    
          
            created(){
       
            },
    
            methods: {
             
              
                menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
                },
            }	   
        
        };
    
    </script> 