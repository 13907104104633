<template>
<div>

<b-row>
<b-col class="col-12 col-sm-6 offset-sm-3">
   
<b-row>
        <b-col class="col-12">
            <h4>Subscription Details</h4> 
        </b-col>

        <b-col class="col-12 mt-1">
            <h5>{{  rover.text }}</h5>
        </b-col>
</b-row>

<b-row class="mt-3">
        <b-col class="col-9"><h5>Machinery</h5></b-col>
        <b-col class="col-3 text-right">
            <b-button variant="outline-success" size="sm" @click.stop="editMachine()"><b-icon-pencil class="mr-2" />edit</b-button>
        </b-col>

        <b-col class="col-5 mt-3">Make</b-col>
        <b-col class="col-7 mt-3">
            <div class="valuedisplay">{{ machine.make_title }}</div>
        </b-col>

        <b-col class="col-5 mt-3">Model</b-col>
        <b-col class="col-7 mt-3">
            <div class="valuedisplay">{{ machine.model_title }}</div>
        </b-col>

        <b-col class="col-5 mt-3">Reg</b-col>
        <b-col class="col-7 mt-3">
            <div class="valuedisplay">{{ machine.regnum  }}</div>
        </b-col>
</b-row>

<b-row class="mt-3">
        <b-col class="col-12"><h5>Subscription</h5></b-col>
        <b-col class="col-5 mt-3">Type</b-col>
        <b-col class="col-7 mt-3">
            <div class="valuedisplay">{{ sub.subscription[0].text }}</div>
        </b-col>

        <b-col class="col-5 mt-3">Start</b-col>
        <b-col class="col-7 mt-3">
            <div class="valuedisplay">{{ formatDate(sub.startdate2) }}</div> 
        </b-col>

        <b-col class="col-5 mt-3">End</b-col>
        <b-col class="col-7 mt-3">
            <div class="valuedisplay">{{ formatDate(sub.enddate2) }}</div> 
        </b-col>

        <b-col class="col-5 mt-3">Remaining</b-col>
        <b-col class="col-7 mt-3">
            <div class="valuedisplay">{{ sub.expirydays }} days</div> 
        </b-col>
</b-row>

<b-row>        
        <b-col class="col-5 mt-3">SIM</b-col>
        <b-col class="col-7 mt-3" v-if="sub.nosimm == 1">
            <div class="valuedisplay">User supplied SIM</div>
        </b-col>
        <b-col class="col-7 mt-3" v-else>
            <div class="valuedisplay">{{ rover.simm[0].text }}</div>
        </b-col>
</b-row>


<b-row class="mt-3" >
        <b-col class="col-9"><h5>NTRIP Details</h5></b-col>
        <b-col class="col-3 text-right">
            <b-button variant="outline-success" @click.stop="editSub()" size="sm" ><b-icon-pencil class="mr-2" />edit</b-button>
        </b-col>
        <b-col class="col-5 mt-3">GSM Username</b-col>
        <b-col class="col-7 mt-3"><div class="valuedisplay">*leave blank</div></b-col>
        <b-col class="col-5 mt-3">GSM Password</b-col>
        <b-col class="col-7 mt-3"><div class="valuedisplay">*leave blank</div></b-col>
        <b-col class="col-5 mt-3">GSM APN</b-col>
        <b-col class="col-7 mt-3"><div class="valuedisplay">APN??</div></b-col>
        <b-col class="col-5 mt-3">Roaming</b-col>
        <b-col class="col-7 mt-3"><div class="valuedisplay">Enabled</div></b-col>
        <b-col class="col-5 mt-3">NTRIP Address</b-col>
        <b-col class="col-7 mt-3"><div class="valuedisplay">{{ rover.connection }}</div></b-col>
        <b-col class="col-5 mt-3">NTRIP Port</b-col>
        <b-col class="col-7 mt-3"><div class="valuedisplay">{{ rover.port }}</div></b-col>
        <b-col class="col-5 mt-3">Mount Point</b-col>
        <b-col class="col-7 mt-3"><div class="valuedisplay">RTK_RTCM3</div></b-col>
        <b-col class="col-5 mt-3">NTRIP User</b-col>
        <b-col class="col-7 mt-3"><div class="valuedisplay">{{ rover.username }}</div></b-col>
        <b-col class="col-5 mt-3">NTRIP Password</b-col>
        <b-col class="col-7 mt-3"><div class="valuedisplay">{{ rover.password }}</div></b-col>
</b-row>

<b-row class="pb-5 mt-5">
        <b-col class="col-12 col-sm-5 mt-3">
            <b-button variant="outline-success" @click.stop="renewSub()" block><b-icon-check-circle class="mr-2" />Renew</b-button>
       </b-col>  
       <b-col class="col-12 col-sm-5 offset-sm-2 mt-3">
            <b-button variant="outline-warning" @click.stop="cancel()" block><b-icon-x-circle class="mr-2" />Cancel</b-button>
       </b-col>  
</b-row>  

</b-col>
</b-row>
   
 
</div> 
</template>

<script>
    import moment from 'moment'
    export default {
        name:'SubscriptionList',
        components:{
        },
        data(){
        return {
                token:'',
                userid:0,
                sub:{},
                rover:{},
                machine:{},
                modaltitle:''
                };
                
      },
      
      created(){
            this.token = this.$store.getters.token
            this.userid = this.$store.getters.user.id
            this.rover = this.$store.getters.rovers.filter(roversel => roversel.value == this.$route.params.id)[0]
            this.sub = this.rover.subscriptiondetail[0]
            this.machine = this.rover.machine[0]
            },

   
       methods: {

          
            formatDate(date){
                return moment(date).format('MMM Do YYYY, h:mm a')
            },

            editMachine(){
                this.$router.push({ name: 'machineedit', params: {id:this.rover.value} }).catch(()=>{})
            },

            editSub(){
                this.$router.push({ name: 'subscriptionedit', params: {id:this.rover.value} }).catch(()=>{})
            },

            finish(){
                  history.back()
            },

            cancel(){
              history.back()
            },

		}	   
};
</script>