<template>
  <div>
    <b-container>

      <b-row>

        <b-col class="col-12 col-md-6">{{ editnote.datetime }}</b-col>
        <b-col class="col-12 col-md-6 text-md-right">{{ editnote.name }}</b-col>
        <b-col class="col-12 mt-3">
          <b-form-textarea class="darkinput" v-model="editnote.description" rows="10" />

        </b-col>

      </b-row>

      <b-row class="mt-3">

        <b-col class="col-12 col-md-6">
          <b-button size="sm" block variant="outline-success" @click.stop="saveNote()">Save</b-button>
        </b-col>
        <b-col class="col-12 col-md-6">
          <b-button size="sm" block variant="outline-danger" @click.stop="deleteNote()">Delete</b-button>
        </b-col>

      </b-row>

    </b-container>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" /> 
  </div>
</template>

<script>
import axios from 'axios'
import ErrorLogging from './ErrorLogging.vue';

export default {
  props: ['note'],
  components: { ErrorLogging },
  data() {
    return {
      token: '',
      editnote: JSON.parse(JSON.stringify(this.note)),
      errorresponse: {}
    };

  },

  created() {


  },


  methods: {

    saveNote() {

      if (this.editnote.id > 0) { this.updateNote() } else this.newNote()

    },

    newNote() {
      axios({
        method: 'post',
        headers: { "Authorization": "Bearer " + this.$store.getters.token },
        url: '/api/casternote',
        data: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.token,
          data: this.editnote
        }
      })

        .then((response) => {

          if (response?.data?.code == 999) {
            this.errorresponse = response.data
            return;
          }

          this.$emit('updatemodal');
        })
        .catch((err) => {
          this.errorresponse = err;
        });

    },

    updateNote() {
      axios({
        method: 'put',
        headers: { "Authorization": "Bearer " + this.$store.getters.token },
        url: '/api/casternote',
        data: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.token,
          data: this.editnote
        }
      })

        .then((response) => {

          if (response?.data?.code == 999) {
            this.errorresponse = response.data;
            return;
          }
          
          this.$emit('updatemodal')
        }).catch((err) => {
          this.errorresponse = err;
        });

    },

    deleteNote() {

      axios({
        method: 'delete',
        headers: { "Authorization": "Bearer " + this.$store.getters.token },
        url: '/api/casternote/' + this.editnote.id,
        data: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.token,
        }
      })

        .then((response) => {

          if (response?.data?.code == 999) {
            this.errorresponse = response.data;
            return;
          } 
          
          this.$emit('updatemodal')
        }).catch((err) => {
          this.errorresponse = err;
        });

    },



    closemodal() {
      this.$emit('closemodal')
    },

  }

};

</script>