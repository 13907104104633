<template>
  <div class="borderlight p">

    <div class="sectioncontcard" v-if="messages.length > 0">

      <b-row class="mt-3 pl-2">
        <b-col class="col-12"
          style="color:rgba(255,255,255,1);font-weight:bolder;font-size:0.9em;margin-left:5px">LATEST EVENTS</b-col>
      </b-row>

      <b-row class="mt-1" style="font-size:0.8em">
        <b-col class="col-12">
          <div style="height:440px;width: 98%;overflow-y: scroll;">
            <b-table striped responsive hover :items="messages" :fields="fields" sort-icon-left
              :tbody-tr-class="setRowClass" thead-tr-class="rowHClass" dark>

              <template #cell(colour)="data">
                <div class="statusbutton" :style="{ backgroundColor: getColor(data.item.Message_code) }" />
              </template>

              <template #cell(message)="data">
                {{ data.item.Username }}<br />{{ data.item.Description }}<br/> {{ gettime(data.item.Timestamp) }}
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

      <!-- tbody-tr-class="rowClassSm" -->

    </div>

    <div v-else>
      <b-row class="mt-3 pl-2">
        <b-col class="col-12"
          style="color:rgba(255,255,255,1);font-weight:bolder;font-size:0.9em;margin-left:5px">LATEST
          EVENTS</b-col>
      </b-row>

      <b-row class="mt-0 mb-5">
        <b-col class="col-12 mt-5 text-center" v-if="nodata">
          <b-spinner class="align-middle"></b-spinner>
          <h5 style="margin-top:10px"><strong>GETTING SESSION DATA</strong></h5>
        </b-col>
        <b-col class="col-12 mt-5 text-center" v-else>
          <h5 style="margin-top:10px"><strong>ROVER NOT CONNECTED</strong></h5>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<style scoped>
  ::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}

.statusbutton {
  width: 15px;
  height: 15px;
  margin-top: 2px;
  float: left;
}
</style>

<script>
import moment from 'moment'
export default {
  name: 'RoverLastEvents',
  props: ['all', 'id', 'events'],
  data() {
    return {
      roverid: 0,
      fields: [
        { key: 'Colour', label: '', sortable: false }, 
        { key: 'Message', label: '', sortable: false }
      ],
      messages: [],
      display: true,
      nodata: true,
      timeout: '',
      token: ''
    };

  },

  created() {
    this.token = this.$store.getters.token
    if (this.$store.getters.token == '') { 
      this.$router.push({ name: 'logout', params: {} }).catch(() => { }); 
    }
    else this.roverid = this.id

    this.messages = this.events
  },

  

  watch: {

    events: function () {
      if (this.events != null) {
        this.messages = this.events
        this.nodata = false
      } else this.nodata = true
    },
  },

  methods: {

    setRowClass(item) {
      this.time = Math.round(+new Date() / 1000)
      if ((this.time - item.timestamp) > 120) { return 'eventold' } else return 'eventnew'
    },


    gettime(time) {
      time = moment(time).add(1, 'hours') // BST fiddle 
      let datetime = moment(time).format('HH:mm:ss DD-MM-YYYY')
      return datetime
    },

    getColor(code){
        let color = 'white'
        switch(code){
          case 1:
          case 2:  
          color = 'green'
          break
          case 3:
          color = 'red'
          break   
        }
        return color
    }

  }

};

</script>