<template>
  <div>


  <!--
    What to have on home page normally?
    stats:
    -how many rovers connected out of # rovers
    -coverage how many bases are online with link to map
    or
    -Limit Rovers to 2 or so most relevent rovers, with link to the rover page       

    Company Activity? - link to manage
    maps?
  -->

  <b-row class="mt-3">
    <b-col class="col-12">
      <RoversListCard />
    </b-col>
  </b-row>

  <!--Subs nearest to renewal, 2 entries-->
  <b-row class="mt-3">
    <b-col>
      <SubscriptionsList/>
    </b-col>
  </b-row>

    

  </div>
</template>


<script>
import RoversListCard from './RoversListCard';
import SubscriptionsList from './SubscriptionsList';

export default {
  name: 'HomePage',
  components: {
    RoversListCard,
    SubscriptionsList,
  },
  data() {
    return {
      rovers: [],
    };
  },


  created() {
    this.rovers = this.$store.getters.rovers;
  },

  methods: {


    menuLink(routeName, routeParams={}) {
      this.$router.push({ name: routeName, params: routeParams }).catch(() => { })
    },

  }

};

</script>