<template>
  <div>

    <b-row>
      <b-col class="col-12 col-sm-6">
        <b-icon-map class="largeicontop" style="float:left;margin-right:25px" />
        <h4 style="margin-top:5px;">Rovers Map</h4>
      </b-col>
    </b-row>

    <b-row class="mt-0">
      <b-col class="col-12 mt-1 p-2" ref="mapdiv">
        <div v-if="display">
        <MapLeafletRovers 
          :mapheight="mapheight"
          :mapwidth="mapwidth" 
          />
        </div>   
      </b-col>
    </b-row>

    <b-row v-if="roverslist.length !== 0 && display">
      <b-col class="col-12 col-sm-6 mt-3 p-0" v-for="rover in roverslist" :key="rover.id" @click.stop="viewRover(rover)">
        <div class="box" style="min-height:0px">

          <b-row class="p-3">
            <b-col class="col-12">
              <b-row>
                <b-col class="col-8">
                  <h5>{{ rover.text }}</h5>
                </b-col>
                <b-col class="col-4 text-right">
                  <b-icon-geo class="largeicon" />
                </b-col>

              </b-row>

              <b-row class="mt-1">

                <b-col class="col-6">
                  <div class="statusbutton" :style="{ backgroundColor: rover.color, marginRight: '10px' }" />
                  {{ rover.statustext }}
                </b-col>
                <b-col class="col-6 text-right">
                  {{ rover.num_sats }} Satellites
                </b-col>
              </b-row>
            </b-col>
          </b-row>

        </div>

      </b-col>
    </b-row>

    <b-row class="mt-0 pb-5">    

      <b-col class="col-12 mt-1 pl-2 pr-2" style="height:500px" v-if="display">
        <RoverLastEvents :events="messages" :all=1 />
      </b-col>
    </b-row>

    <b-row v-if="roverslist.length == 0 && display">
      <b-col class="col-12 col-sm-6 offset-sm-3 mt-5 p-3">
        <div class="statusbox" style="background-color:#993a3a ;padding-top:10px;">
          <h4>No active Rovers</h4>
        </div>
      </b-col>
    </b-row>

    <LoadCompanyData :reloaddata="reloaddata" :dialog="false"/>

  </div>
</template>

<style>
  .statusbutton {
    width: 15px;
    height: 15px;
    margin-top: 4px;
    float: left;
  }
</style>

<script>
import MapLeafletRovers from './MapLeafletRovers'
import RoverLastEvents from './RoverLastEvents'
import LoadCompanyData from './LoadCompanyData'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  name: 'RoversDisplay',
  components: {
    MapLeafletRovers,
    RoverLastEvents,
    LoadCompanyData
  },
  data() {
    return {
      company: 0,
      token: '',
      roverslist: [],
      messages: [],
      timeout: false,
      display: false,
      status: 0,
      showmap: false,
      mapwidth:0,
      mapheight:0,
      reloaddata:false
    };
  },


  created() {
    window.addEventListener("resize", this.setMapSize)
  },

  mounted(){
    this.roverslist = this.$store.getters.roversmap
    this.messages = this.$store.getters.roversevents
    // Trigger live rovers data get
    this.timeout = setInterval(() => { this.triggerLoadData() }, 5000)
    this.setMapSize()
    
  },

  beforeDestroy() {
    clearInterval(this.timeout)
    window.removeEventListener("resize", this.setMapSize)
  },

  computed: mapState(['roversmap','roversevents']),

  watch:{
      roversmap () {
          this.roverslist = this.roversmap
          this.messages = this.roversevents
          console.log('reloaded')
      },

      roversevents () {
          this.messages = this.roversevents
      },
  },    


  methods: {

    setMapSize(){
        //this.mapheight2 = 400 // Set height of bordered box
        this.mapheight = 400 // Set leaflet map height
        this.mapwidth =  this.$refs['mapdiv'].clientWidth - 20 // Set leaflet map width
        this.display = true
    },

    addRover() {
      this.$refs['rover-add'].show()
    },

    closeModal() {
      this.$refs['rover-add'].hide()
    },

  
    showMap() {
      this.showmap = true
    },

    fullScreen() {
      // this.modalmapwidth = 0
      // this.$refs['full-screen'].show()
    },

    waitSetWidth() {
      //setTimeout(this.setModalWidth, 150)
    },

    setModalWidth() {
      //prety sure this would be recursive and not needed. window.addEventListener("resize", this.setModalWidth)
      this.modalmapwidth = this.$refs['fullscreendiv'].clientWidth
    },

    setModalHeight(){
      this.modalmapheight = window.innerHeight * 0.9;
    },

    modalSizeAddWindowResizeListener(){
      this.setModalHeight(); //call once to initialise height.
      window.addEventListener("resize", this.setModalWidth);
      window.addEventListener('resize', this.setModalHeight);
    },

    modalSizeRemoveWindowResizeListener(){
      window.removeEventListener("resize", this.setModalWidth);
      window.removeEventListener('resize', this.setModalHeight);
    },

    closeMap() {
      window.removeEventListener("resize", this.setModalWidth)
      this.$refs['full-screen'].hide()
    },

    triggerLoadData(){
      this.reloaddata = 6
      setTimeout(() => {this.reloaddata = 0}, 2000);
    },

    viewRover(rover) {
      clearTimeout(this.timeout)
      this.$router.push({ name: 'roverdisplay', params: { id: rover.value } }).catch(() => { })
    },

    formatDate(timedate) {
      if (timedate == 0) { return '-' }
      let date = moment(timedate * 1000).format('DD-MM-YY HH:mm')
      return date
    },

    formatTime(timedate) {
      if (timedate == 0) { return '-' }
      let date = moment(timedate * 1000).format('HH:mm:ss')
      return date
    },

    formatPosition(pos) {
      let value = pos * 100000000000000000
      value = Math.round(pos * 100000000) / 100000000
      return value
    }

  }

};

</script>