import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

const store = new Vuex.Store({
        
    plugins: [createPersistedState({
        paths: ['admindialog','token','user','subsel','rover','rovers','roversmap','roversevents','roversession','subs','stocksubs','sims','stocksims','loggedin','companyid','company','sessionid','basket', 'baskettotal', 'payment', 'paymentintent', 'paymentcomplete','coupon' ],
      })],
          
           state: {
              admindialog:false,
              token:'',
              user:{},
              rover:[],
              roverid:0,
              rovers:[],
              roversmap:[],
              roversevents:[],
              roversession:{},
              subs:[],
              stocksubs:[],
              stocksims:[],
              sims:[],
              companyid:0,
              company:{},
              subsel:{},
              sessionid:0,
              loggedin:false,
              openmenu:false,
              order:[],
              paymentintent:'',
              payment:{},
              paymentcomplete: false,
              basket:[],
              baskettotal:0,
              mobile:false,
              coupon:'',
          },
          mutations: {
              setadmindialog(state, admindialog) {
                     state.admindialog = admindialog
              },

              settoken(state, token) {
                     state.token = token
              },

              setuser(state, user) {
                     state.user = user
              },
            
              setrovers(state, rovers){
                     state.rovers = rovers
              },

              setroversmap(state, roversmap){
                     state.roversmap = roversmap
              },

              setroversevents(state, roversevents){
                     state.roversevents = roversevents
              },

              setroversession(state, roversession){
                state.roversession = roversession
              },

              setroverid(state, roverid){
                     state.roverid = roverid
              },

              setsubs(state, subs){
                     state.subs = subs
              },

              setstocksubs(state, stocksubs){
                     state.stocksubs = stocksubs
              },

              setstocksims(state, stocksims){
                     state.stocksims = stocksims
              },

              setsims(state, sims){
                     state.sims = sims
              },

              setsubsel(state, subsel) {
                     state.subsel = subsel
              },
  
              setcompany(state, company) {
                     state.company = company
              },

              setcompanyid(state, companyid) {
                     state.companyid = companyid
              },

              setsessionid(state, sessionid) {
                     state.sessionid = sessionid
              },

              setrover(state, rover) {
                     state.rover = rover
              },
  
              setloggedin(state, loggedin) {
                     state.loggedin = loggedin
              },
  
              setopenmenu(state, openmenu) {
                     state.openmenu = openmenu
              },
              setorder(state, order) {
                     state.order = order
              },
              setpaymentintent(state, paymentintent) {
                     state.paymentintent = paymentintent
              },  
              setpaymentcomplete(state, paymentcomplete) {
                     state.paymentcomplete = paymentcomplete
              },
              setuserdetailscomplete(state, userdetailscomplete) {
                     state.userdetailscomplete = userdetailscomplete
              },
              setpayment(state, payment) {
                     state.payment = payment
              },
              setuserdetails(state, userdetail) {
                     state.order.userdetails = userdetail
              },
              setcardholderdetails(state, chdetail) {
                     state.order.cardholder = chdetail
              },
              setdeliverydetails(state, deldetail) {
                     state.order.delivery = deldetail
              },
              setbasket(state, basket) {
                     state.basket = basket
              },
              setbaskettotal(state, baskettotal) {
                     state.baskettotal = baskettotal
              },
              setmobile(state, mobile){
                     state.mobile = mobile
              },
            
              setcoupon(state,coupon){
                     state.coupon = coupon
              },
  
         },
          getters: {
              admindialog (state) {
                     return state.admindialog
              },

              token (state) {
                     return state.token
              },

              user (state) {
                     return state.user 
              },
             
              rovers (state){
                     return state.rovers
              },

              roversmap (state){
                     return state.roversmap
              },

              roversevents (state){
                     return state.roversevents
              },

              roversession (state){
                return state.roversession
              },

              subs (state){
                     return state.subs
              },

              stocksubs (state){
                     return state.stocksubs
              },

              stocksims (state){
                     return state.stocksims
              },

              sims (state){
                     return state.sims
              },

              subsel (state){
                     return state.subsel
              },

              company (state) {
                     return state.company
              },

              companyid (state) {
                     return state.companyid
              },       

              sessionid (state) {
                     return state.sessionid 
              },

              roverid (state) {
                     return state.roverid
              },

              rover (state) {
                     return state.rover
              },

              loggedin (state) {
                      return state.loggedin
              },
              openmenu (state) {
                      return state.openmenu
              },
              order(state) {
                     return state.order
              },  
              cardholder(state){
                     return state.order.cardholder
              },
              userdetails(state) {
                     return state.order.userdetails
              },
              paymentcomplete(state) {
                     return state.paymentcomplete
              },
              payment(state) {
                     return state.payment
              },
              paymentintent(state) {
                     return state.paymentintent
              },
              basket (state) {
                     return state.basket
              },
              baskettotal (state) {
              return state.baskettotal
              }, 
              mobile (state) {
                     return state.mobile
              },
              coupon (state) {
                     return state.coupon
              },     
          }
      })
      
export default store